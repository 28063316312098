<template>
	<div class="path-courses__wrapper">
		<div class="path-courses pt-4">
			<h3 class="path-results__title mb-4">
				Kapitoly
			</h3>
			<router-link v-for="course of path.courses" :key="course.id"
				:to="`/kurz/${course.slug}?seria=${path.slug}`"
				class="path-courses__course d-flex flex-row align-items-center pl-2 rounded-lg"
				:class="{ 'completed': course.is_completed }"
			>
				<div class="path-courses__course__image-container mr-4" :class="{ 'completed': course.is_completed }">
					<b-img class="path-courses__course__image" :src="course.image.path" alt="Thumbnail" fluid></b-img>
					<svg-checked-circle-icon v-if="course.is_completed" class="image__completed-icon" />
				</div>
				<p class="m-0 font-weight-bold">
					<svg-open-lock-icon v-if="!isLoggedIn && course.is_public == '1'" class="is-public-icon w-color-dark-green" />
					<svg-closed-lock-icon v-if="!isLoggedIn && course.is_public == '0'" class="is-public-icon w-color-dark-red" />
					{{ course.name }}
				</p>
			</router-link>
			<div
				class="path-courses__course d-flex flex-row align-items-center justify-content-center pl-2 pr-2 rounded-lg"
				:class="{ issued: path.is_completed }"
			>
				<div class="path-courses__course__content_container d-flex flex-row mb-3 mb-md-0">
					<div class="path-courses__course__image-container mr-4">
						<svg-trophy-icon class="icon-trophy" :class="{ issued: path.is_completed }" />
					</div>
					<p class="path-courses__course__text-container m-0 font-weight-bold">
						{{ certificateText }}
					</p>
				</div>
				<b-button v-if="path.is_completed" class="download-btn inline-block" @click="downloadCertificate">
					Stiahnuť diplom
				</b-button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import wAxios from '@/plugins/w/axios'
import { downloadCertificate } from '@/plugins/appzmudri@profile/certificates/lib/downloadCertificate'

const api = {
	getCertificates: () => wAxios.get_auth_data(`v1/certificates`)
}

export default {
	components: {
		'svg-checked-circle-icon':		() => import('@/plugins/appzmudri/_theme/icon/checked-circle.svg?inline'),
		'svg-open-lock-icon': 			() => import('@/plugins/appzmudri@component/course-card/assets/open-lock.svg?inline'),
		'svg-closed-lock-icon': 		() => import('@/plugins/appzmudri@component/course-card/assets/closed-lock.svg?inline'),
		'svg-trophy-icon': 				() => import('@/plugins/lib@course/path/icon/trophy.svg?inline')
	},
	props: {
		path: { required: true }
	},
	data() {
		return {
			certificate: null
		}
	},
	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn',
			'user'
		]),
		isAdmin() {
			return this.user && this.user.profile && this.user.profile.type == 'teacher'
		},
		certificateText() {
			if (this.path.is_completed) {
				return this.isAdmin
					? 'Gratulujeme, získali ste diplom!'
					: 'Gratulujeme, získal/a si diplom!'
			}

			return this.isAdmin
				? 'Po vyplnení všetkých kvízov získavate diplom'
				: 'Po vyplnení všetkých kvízov získavaš diplom'
		}
	},

	async created() {
		if (!this.isLoggedIn) {
			return
		}

		await this._loadCertificate()
	},

	methods: {
		async _loadCertificate() {
			const certificates = await api.getCertificates()
			this.certificate = certificates.filter(certificate => certificate.path.slug === this.path.slug)[0]
		},
		downloadCertificate() {
			downloadCertificate(this.certificate)
		}
	}
}
</script>

<style lang="scss" scoped>
.path-courses__wrapper {
	margin-right: 1.3125rem;
	border-image: linear-gradient(90.01deg, #0064FF 60.75%, #FB2564 79.89%) 1;
	border-bottom-width: 5px;
	border-bottom-style: solid;

	.path-courses {
		padding-left: 2.125rem;
		padding-right: 2.0625rem;
		padding-bottom: 3.4375rem;
		border: 1px solid var(--a-color-blue-border);
		border-bottom-width: 0;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;

		.path-courses__course {
			padding-top: 1rem;
			padding-bottom: 1rem;
			border-bottom: 1px solid var(--a-color-blue-border);

			&.completed {
				background-color: rgba(144,238,144, 0.2);
			}

			&.issued {
				background-color: #EB1B69;
			}

			&:last-child {
				flex-wrap: wrap;
				border-bottom: none;
			}

			.path-courses__course__content_container {
				flex-grow: 1;
			}

			.path-courses__course__image-container {
				position: relative;
				flex: 0 0 4.5rem;
				display: flex;
				align-items: center;
				justify-content: center;

				&.completed {
					&::before {
						content: "";
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						background-color: lightgreen;
						opacity: 0.9;
						z-index: 1;
					}
				}

				.image__completed-icon {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0; 
					right: 0; 
					margin: auto;
					width: 25px;
					color: w-color-black;
					z-index: 2;
				}

				.path-courses__course__image {
					height: auto;
					border-radius: 3px;
				}
			}

			.path-courses__course__text-container {
				flex-grow: 1;
			}
		}

		a:hover {
			text-decoration: none;
			background-color: var(--a-color-blue-light);
		}

		a:active {
			background-color: var(--a-color-blue-shadow);
		}
	}
}

.is-public-icon {
	width: 1.5rem;
	margin-right: 0.25rem;
}

.icon-trophy {
	color: var(--a-color-secondary);
	width: 2rem;
	height: 2rem;

	&.issued {
		color: var(--a-color-yellow);
	}
}

// TODO: Fix button colors
::v-deep .download-btn {
	width: 136px;
	background-color: #2B2E4A;
	color: white !important;
	border-radius: 100px;
	border-color: #2B2E4A !important;
	font-size: small;
	font-weight: bold;
	min-width: 136px;
	min-height: 39px;
	padding: 0;

	&:hover {
		background-color: #FFFFFF;
		color: #2B2E4A !important;
		border-color: #FFFFFF !important;
	}
}
</style>